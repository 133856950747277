import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Aviso = () => (
  <Layout>
    <SEO
      title="Mision y valores"
      description="
          Nuestra mision es lograr la satisfaccion de nuestros clientes a traves
          de un servicio de calidad y un trato agradable. Mediante el trabajo en
          equipo buscamos siempre mantenernos como numero uno en limpiadurias de
          la ciudad de Mexicali.
          "
    />
    <div className="mc-wrapper-2 extra-padding">
      <div className="mc-wrapper-3 extra">
        <h1 className="title">Aviso de privacidad</h1>
        <p className="first">
          LIMPIADURIA HUIZAR DE MEXICALI S.A, mejor conocido como MA. ELENA
          NUÑEZ FLORES, con domicilio en calle Avenida Ignacio Zaragoza, #2094,
          colonia Nueva, ciudad Mexicali, municipio o delegación Baja
          California, c.p. 21100, en la entidad de Baja California, país México,
          y portal de internet Ihuizar@prodigy.net.mx, es el responsable del uso
          y protección de sus datos personales, y al respecto le informamos lo
          siguiente:
        </p>
        <h2>¿Para qué fines utilizaremos sus datos personales?</h2>
        <p>
          De manera adicional, utilizaremos su información personal para las
          siguientes finalidades secundarias que no son necesarias para el
          servicio solicitado, pero que nos permiten y facilitan brindarle una
          mejor atención:
        </p>
        <ul>
          <li>Proveer servicios y productos que ha solicitado</li>
          <li>
            Notificar sobre nuevos productos o servicios que tengan relacion con
            los ya contratados
          </li>
          <li>Evaluar la calidad del servicio que brindamos</li>
          <li>Mejorar la calidad del servicio</li>
          <li>Mercadotecnia o publicitaria</li>
          <li>Prospección comercial</li>
        </ul>
        <p>
          En caso de que no desee que sus datos personales se utilicen para
          estos fines secundarios, indíquelo a continuación.
          <br />
          No consiento que mis datos personales se utilicen para los siguientes
          fines:
        </p>
        <ul className="no-bullet">
          <li>
            [&nbsp;&nbsp;] Proveer servicios y productos que ha solicitado
          </li>
          <li>
            [&nbsp;&nbsp;] Notificar sobre nuevos productos o servicios que
            tengan relacion con los ya contratados
          </li>
          <li>[&nbsp;&nbsp;] Evaluar la calidad del servicio que brindamos</li>
          <li>[&nbsp;&nbsp;] Mejorar la calidad del servicio</li>
          <li>[&nbsp;&nbsp;] Mercadotecnia o publicitaria</li>
          <li>[&nbsp;&nbsp;] Prospección comercial</li>
        </ul>
        <p>
          La negativa para el uso de sus datos personales para estas finalidades
          no podrá ser un motivo para que le neguemos los servicios y productos
          que solicita o contrata con nosotros.
        </p>
        <h2>¿Qué datos personales utilizaremos para estos fines?</h2>
        <p>
          Para llevar a cabo las finalidades descritas en el presente aviso de
          privacidad, utilizaremos los siguientes datos personales:
        </p>
        <ul>
          <li>Registro Federal de Contribuyentes(RFC)</li>
          <li>Domicilio</li>
          <li>Teléfono particular</li>
          <li>Teléfono celular</li>
          <li>Correo electrónico</li>
        </ul>
        <h2>
          ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
          oponerse a su uso?
        </h2>
        <p>
          Usted tiene derecho a conocer qué datos personales tenemos de usted,
          para qué los utilizamos y las condiciones del uso que les damos
          (Acceso). Asimismo, es su derecho solicitar la corrección de su
          información personal en caso de que esté desactualizada, sea inexacta
          o incompleta (Rectificación); que la eliminemos de nuestros registros
          o bases de datos cuando considere que la misma no está siendo
          utilizada adecuadamente (Cancelación); así como oponerse al uso de sus
          datos personales para fines específicos (Oposición). Estos derechos se
          conocen como derechos ARCO.
        </p>
        <p>
          Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
          presentar la solicitud respectiva a través del siguiente medio:
        </p>
        Correo Electronico de la empresa
        <p>
          Para conocer el procedimiento y requisitos para el ejercicio de los
          derechos ARCO, ponemos a su disposición el siguiente medio:
        </p>
        numero de la empresa y correo electronico
        <p>
          Los datos de contacto de la persona o departamento de datos
          personales, que está a cargo de dar trámite a las solicitudes de
          derechos ARCO, son los siguientes:
        </p>
        <ul>
          <li>
            Nombre de la persona o departamento de datos personales: Reyna Iveth
            Moreno Valdez
          </li>
          <li>
            Domicilio: calle Avenida Ignacio Zaragoza# 2094, colonia Nueva,
            ciudad Mexicali, municipio o delegación Mexicali, c.p. 21100, en la
            entidad de Baja California, país México
          </li>
          <li>Correo electrónico: Ihuizar@prodigy.net.mx</li>
        </ul>
        <h2>
          Usted puede revocar su consentimiento para el uso de sus datos
          personales
        </h2>
        <p>
          Usted puede revocar el consentimiento que, en su caso, nos haya
          otorgado para el tratamiento de sus datos personales. Sin embargo, es
          importante que tenga en cuenta que no en todos los casos podremos
          atender su solicitud o concluir el uso de forma inmediata, ya que es
          posible que por alguna obligación legal requiramos seguir tratando sus
          datos personales. Asimismo, usted deberá considerar que para ciertos
          fines, la revocación de su consentimiento implicará que no le podamos
          seguir prestando el servicio que nos solicitó, o la conclusión de su
          relación con nosotros.
        </p>
        <p>
          Para revocar su consentimiento deberá presentar su solicitud a través
          del siguiente medio:
        </p>
        correo electronico
        <p>
          Para conocer el procedimiento y requisitos para la revocación del
          consentimiento, ponemos a su disposición el siguiente medio:
        </p>
        medios de internet
        <h2>
          ¿Cómo puede limitar el uso o divulgación de su información personal?
        </h2>
        <p>
          Con objeto de que usted pueda limitar el uso y divulgación de su
          información personal, le ofrecemos los siguientes medios:
        </p>
        correo electronico
        <h2>¿Cómo puede conocer los cambios en este aviso de privacidad?</h2>
        <p>
          El presente aviso de privacidad puede sufrir modificaciones, cambios o
          actualizaciones derivadas de nuevos requerimientos legales; de
          nuestras propias necesidades por los productos o servicios que
          ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
          modelo de negocio, o por otras causas.
        </p>
        <p>
          Nos comprometemos a mantenerlo informado sobre los cambios que pueda
          sufrir el presente aviso de privacidad, a través de: correo
          electronico y pagina web.
        </p>
        <p>
          El procedimiento a través del cual se llevarán a cabo las
          notificaciones sobre cambios o actualizaciones al presente aviso de
          privacidad es el siguiente:
        </p>
        presencial y via correo electronico
      </div>
    </div>
  </Layout>
)

export default Aviso
